import React from 'react';
import './hero.css';
import Header from '../Header/Header';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import heart from '../../assets/heart.png';
import calories from '../../assets/calories.png';
import {motion} from 'framer-motion'
import { type } from '@testing-library/user-event/dist/type';
import NumberCounter from "number-counter"

export default function Hero() {
    const transition={type:'spring',duration:3 }
  return (
    <div className='hero' id='home'>
        <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header/>
        {/*The best ad*/ }
            <div className="the-best-ad">
                <motion.div
                initial={{left:'238px'}}
                whileInView={{left:'8px'}}
                transition={{...transition, type:'tween'}}
                ></motion.div>
                <span>The best fitness club in the town</span>
            </div>
        {/*hero heading*/}
        <div className="hero-text">
            <div>
                <span className='stroke-text'>Shape </span>
                <span>Your</span>
            </div>
            <div>
                <span>Ideal Body</span>
            </div>
            <div>
                <span> In here we will help you to shape and build your ideal body and live up your life to fullest </span>
            </div>
        </div>
        {/*figure*/}
        <div className="figures">
            <div>
                <span>
                    <NumberCounter end={140} start={100} delay='4' preFix="+"/>
                </span>
                <span>Fitness couches</span>
            </div>
            <div>
                <span>
                <NumberCounter end={978} start={800} delay='4' preFix="+"/>  
                </span>
                <span>Members joined</span>
            </div>
            <div>
                <span>
                <NumberCounter end={50} start={0} delay='4' preFix="+"/>
                </span>
                <span>Fitness programs</span>
            </div>
        </div>
        {/*Buttons*/}
        <div className="hero-buttons">
            <buttons className="btn">Get Started</buttons>
            <buttons className="btn">Learn More</buttons>
        </div>
        </div>
        {/*right side*/}
        <div className="right-h">
            <button className="btn">Join Now</button>

            
            <motion.div 
            initial={{right:'-1rem'}}
            whileInView={{right:'4rem'}}
            transition={transition}
            className="heart-rate">
                <img src={heart} alt="" />
                <span>Heart Rate</span><span>116 bpm</span>
            </motion.div>
            {/*hero images*/}
            <img src={hero_image} alt="" className="hero-image" />
            <motion.img 
            initial={{right:"11rem"}}
            whileInView={{right:"20rem"}}
            transition={transition}
            src={hero_image_back} alt="" className="hero-image-back" />
            {/*calories */}
            <motion.div 
            initial={{right:'37rem'}}
            whileInView={{right:'28rem'}}
            transition={transition}
            className="calories">
                <img src={calories} alt="" />
                <div>
                <span className='calSpan1'>Calories Burned</span>
                <span className='calSpan2'>220 kcal</span>
                </div>
            </motion.div>
        </div>
    </div>
  )
}
