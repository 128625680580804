import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import './join.css'
const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_30qd1e9', 'template_3osavep', form.current, {
        publicKey: '4O3O43od3uUMUs5ZK',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      };


  return (
    <div className="join-us">
        <div className="left-j">
            <hr />
              <div>
                <span className='stroke-text'>READY TO</span>
                <span>LEVEL UP</span>
              </div>
              <div>
                <span>YOUR BODY</span>
                <span className='stroke-text'>WITH US?</span>
              </div>
        </div>
        <div className="right-j">
              <form ref={form} className='email-container' onSubmit={sendEmail}>
                 <input type="email" name="user_email" placeholder='Enter your email address' />
                 <button className="btn btn-j">Join Now</button>
              </form>
        </div>
    </div>

    
  )
}

export default Join